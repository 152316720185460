@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

// Variables
$opensans: 'Open Sans', sans-serif;
$playfair: 'Playfair Display', serif;

// Mixins
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: $opensans;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

a.anchor {
    scroll-margin-top: 150px;
}

button,
a.button,
input.button {
    padding: 12px 30px;
    border: 3px solid #000;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        background-color: #000;
        color: #fff;
    }
}

p {
    @include font-size(1.1);
    font-weight: 400;
    line-height: 1.8;
}

h1 {
    font-family: $playfair;
    @include font-size(2.5);
    text-align: center;
}

h2 {
    font-family: $playfair;
    @include font-size(2.5);
    font-weight: lighter;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1;
}

h3 {
    @include font-size(1.5);
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1;
}

hr {
    border-top: 1px solid #ececec;
}

select {
    margin: 0 25px 0 15px;
    padding: 10px 55px 10px 20px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0;
    @include font-size(1);
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(45deg, transparent 50%, #000 50%),
        linear-gradient(135deg, #000 50%, transparent 50%);
    background-position:
        calc(100% - 21px) calc(1em + 2px),
        calc(100% - 16px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

.fullwidth-advertisement {
    padding: 10px;
    background-color: #000;
    text-align: center;
    position: relative;
    z-index: 999;

    p {
        color: #333;
        @include font-size(0.8);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 0;
    }

    .report-ad-button {
        color: #797676 !important;
    }
}

header {
    padding-top: 10px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    @media (max-width: 1000px) {
        padding-bottom: 50px;
    }

    @media (max-width: 600px) {
        padding-bottom: 0;
        min-height: 50px;
    }

    .header-content {
        max-width: 1400px;
        margin: auto;
        position: relative;

        .fa-bars {
            @include font-size(1.5);
            position: absolute;
            top: 7px;
            left: 20px;
            display: none;

            @media (max-width: 1000px) {
                display: block;
            }
        }
    }

    img#smithmagLogo,
    img#smithmagLogoFooter {
        width: 150px;
        margin-bottom: 5px;

        @media (max-width: 1000px) {
            margin-left: 55px;
            float: left;
        }
    }

    .login-join,
    .entries-username {
        color: #000;
        text-transform: uppercase;
        @include font-size(0.9);
        font-weight: 600;
        letter-spacing: 1px;
        position: absolute;
        top: 13px;
        right: 25px;

        @media (max-width: 1000px) {
            top: 10px;
        }

        @media (max-width: 350px) {
            right: 10px;
        }

        a span {
            font-weight: 300;
        }
    }

    .entries-username {
        position: absolute;
        top: -10px;
        cursor: pointer;

        @media (max-width: 600px) {
            position: relative;
            top: inherit;
            right: inherit;
            clear: both;
        }

        .dropdown-trigger {
            padding: 20px 15px;
            @include font-size(0.9);
            font-weight: 400;
            text-transform: none;
            display: inline-block;
            vertical-align: middle;

            @media (max-width: 600px) {
                margin-top: 5px;
                padding: 10px 15px;
            }

            &:hover {
                background-color: #000;
                color: #fff;
            }

            i.fa {
                color: #ccc;
                @include font-size(0.5);
            }

            i.fa-caret-down {
                display: none;
            }

            .dropdown {
                width: 193px;
                padding: 30px 55px 40px;
                background-color: #000;
                color: #fff;
                text-align: left;
                position: absolute;
                top: 58px;
                right: 0;
                display: none;

                @media (max-width: 600px) {
                    width: 100%;
                    top: 44px;
                    left: 0;
                    right: 0;
                }

                a {
                    margin: 15px 0;
                    @include font-size(0.8);
                    display: block;
                }

                strong {
                    margin: 15px 0;
                    @include font-size(0.9);
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    display: block;
                }
            }
        }

        .dropdown-trigger:hover .dropdown {
            display: inline-block;
        }
    }

    nav {
        border-top: 1px solid #e5e5e5;

        @media (max-width: 1000px) {
            height: 100vh;
            margin-top: -200vh;
            padding-top: 100px;
            background-color: rgba(255, 255, 255, 0.9);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }

        @media (max-width: 400px) {
            padding-top: 60px;
        }

        .fa-times {
            @include font-size(1.8);
            position: absolute;
            top: 17px;
            left: 20px;
            display: none;
        }

        a {
            margin: 0 10px;
            padding: 20px 18px 15px;
            color: #000;
            @include font-size(0.9);
            font-weight: 600;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 1px;
            display: inline-block;

            @media (max-width: 1100px) {
                padding: 19px 10px;
            }

            @media (max-width: 1000px) {
                padding: 10px;
                display: block;
            }

            &.active,
            &:hover {
                padding-bottom: 13px;
                border-bottom: 5px solid #000;
                text-decoration: none;
            }

            &:first-of-type {
                padding-top: 15px;
                padding-bottom: 15px;
                @include font-size(1.2);
                font-weight: 800;
                letter-spacing: 0;
                float: left;

                @media (max-width: 1000px) {
                    padding: 10px;
                    float: none;
                }

                &:hover {
                    padding-bottom: 15px;
                    border-bottom: none;
                    text-decoration: none;
                }
            }
        }

        .nav-dropdown {
            width: 200%;
            margin-left: -50%;
            padding: 50px 0 80px;
            background-color: #fff;
            border-bottom: 1px solid #ececec;
            position: absolute;
            left: 0;
            right: 0;
            display: none;
            z-index: 8;

            @media (max-width: 1000px) {
                border-bottom: none;
            }

            &:hover {
                display: block;
            }

            a,
            a:first-of-type {
                padding: 0 30px;
                font-family: $playfair;
                @include font-size(1.1);
                font-weight: 500;
                text-transform: none;
                letter-spacing: 0;
                float: none;

                &:hover {
                    border-bottom: none;
                }
            }

            .link-wrapper {
                max-width: 1140px;
                margin: auto;
                text-align: left;

                a,
                a:first-of-type {
                    width: 200px;
                    height: 40px;
                    text-align: center;
                }
            }
        }

        a.menu-option:hover + .nav-dropdown {
            display: block;
        }

        .submit-share-search {
            margin: 15px 15px 0 0;
            float: right;

            @media (max-width: 1000px) {
                margin: auto;
                float: none;
            }

            a {
                padding: 5px 0 19px;
                @include font-size(0.8);
                font-weight: 800;

                &:hover {
                    border-bottom: none;
                }

                &:first-of-type {
                    margin-right: 20px;
                    padding: 5px 25px;
                    background-color: #000;
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 2px;

                    @media (max-width: 1000px) {
                        width: 120px;
                        margin: auto;
                    }
                }

                &:nth-of-type(2) {
                    @media (max-width: 1000px) {
                        display: none;
                    }
                }

                .fa-search {
                    @media (max-width: 1000px) {
                        @include font-size(1.5);
                    }
                }
            }

            .share-dropdown {
                padding: 20px 0;
                border-top: 1px solid #e5e5e5;
                top: 109px;

                @media (max-width: 1000px) {
                    width: auto;
                    margin: auto;
                    background-color: transparent;
                    position: relative;
                    display: block;
                }

                a,
                a:first-of-type {
                    width: auto;
                    margin: 0 20px;
                    padding: 0;
                    background-color: #fff;
                    color: #000;
                    display: inline-block;
                }

                i {
                    color: #000;
                    @include font-size(1.2);
                }
            }
        }
    }
}

.sidebar-advertisement {
    padding: 0 0 50px;
    text-align: center;

    p {
        color: #333;
        font-size: 8px !important;
        font-size: 0.8rem !important;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 0;
    }
}

.sponsorship-banner {
    position: relative;
    width: 100%;
    background: #000;
    align-items: center;
    padding: 5px 0;
    p {
        color: #fff;
        text-align: center;
        @media (max-width: 600px) {
            padding: 0 20px;
            line-height: 120%;
        }
    }
    img {
        width: 67px;
        height: auto;
        padding-left: 10px;
        vertical-align: middle;
        @media (max-width: 600px) {
            padding-top: 10px;
            padding-left: 20px;
        }
    }
    strong {
        @media (max-width: 600px) {
            display: block;
            font-size: 1.2rem;
            padding-bottom: 10px;
        }
    }
    .seperator {
        padding: 0 30px;
        @media (max-width: 600px) {
            display: none;
            padding: 0;
        }
    }
    span {
        @media (max-width: 600px) {
            font-size: 0.9rem;
            width: 170px;
            padding-top: 5px;
            display: inline-block;
            text-align: right;
            position: relative;
            top: 15px;
        }
    }
}

.billboard {
    width: 100vw;
    position: relative;
    @media (max-width: 600px) {
        height: 20vh;
        padding-top: 100px;
        width: auto;
        background: url('https://tf-cmsv2-photocontest-media.s3.amazonaws.com/photocontest/billboard/Smithmag-PhotoContest21-WebsiteBanner-1428x645-notext.jpg')
            top left no-repeat;
    }

    img {
        width: 100%;
    }
    .billboard-image-photocontest {
        @media (max-width: 600px) {
            display: none;
        }
    }

    .text-wrapper {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 5px;
        @media (max-width: 600px) {
            bottom: 0;
        }
        @media (max-width: 1000px) {
            padding: 30px;
        }
        .headings-wrapper {
            position: absolute;
            left: 60px;
            right: 60px;
            bottom: 30px;
            @media (max-width: 600px) {
                left: 20px;
                right: 20px;
                bottom: 0;
                top: 20px;
            }
            h2,
            h3 {
                color: #fff;
                font-family: $playfair;
                @include font-size(2.5);
                font-weight: 300;

                @media (max-width: 1000px) {
                    text-align: left;
                }
                @media (max-width: 600px) {
                    text-align: center !important;
                }
            }

            h3 {
                margin: 20px 0;

                @media (max-width: 600px) {
                    @include font-size(2);
                }
            }

            h2 {
                font-family: $opensans;
                @include font-size(1.5);
                font-weight: 600;
                text-transform: uppercase;
                text-align: left;
                letter-spacing: 2px;
                line-height: 1.2;

                @media (max-width: 600px) {
                    padding: 0;
                    margin-top: 0;
                    @include font-size(1);
                }
            }
            .headingGrid {
                display: grid;
                grid-template-columns: 1fr 375px;
                gap: 40px;
                align-items: center;
                @media (max-width: 600px) {
                    display: block;
                    grid-template-columns: 1fr 450px;
                    gap: 40px;
                }
                .headingRight {
                    display: grid;
                    grid-template-columns: 1fr 100px;
                    gap: 40px;
                    align-items: center;
                    @media (max-width: 600px) {
                        grid-template-columns: 1fr 130px;
                        gap: 30px;
                    }
                    p {
                        color: #fff;
                        line-height: 120%;
                        margin: 0;
                        padding: 0;
                        text-align: right;
                        @media (max-width: 600px) {
                            font-size: 1rem;
                        }
                    }
                    img {
                        width: 100px;
                        height: auto;
                    }
                }
            }
        }
    }
}

// Homepage
section.featured-entries .photo-grid-photos .photo-container {
    border-bottom: none;
}

// Categories
section.photo-categories {
    padding: 0 50px;

    @media (max-width: 600px) {
        padding: 0;
    }
}

.main {
    max-width: 1000px;
    margin: auto;
    padding: 0 20px;

    .breadcrumbs {
        margin: 50px auto 60px;
        @include font-size(1);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;

        a {
            font-weight: 700;

            &:after {
                content: '/';
                padding: 0 5px 0 10px;
                font-weight: lighter;
            }
        }
    }

    section {
        padding: 50px 0;

        @media (max-width: 1000px) {
            padding: 0;
        }

        &.winners {
            padding: 0 0 50px;
        }

        &.winners-category {
            h2,
            h3 {
                margin: 20px auto;
            }

            h3 {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }

    // Photo with text overlay
    .text-overlay-image {
        width: 300px;
        height: 250px;
        margin: 0 auto 55px;
        margin-left: 25px;
        margin-right: 25px;
        background-position: center;
        background-size: cover;
        position: relative;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;

        .text-overlay {
            padding: 0 40px;
            color: #fff;
            @include font-size(1.6);
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .grid-ad {
            margin-bottom: 0;
        }
    }

    // Photo Detail Page
    .photo-detail {
        width: 100%;
        margin: 0 auto 50px;
        text-align: center;
        position: relative;

        img {
            width: 100%;
            display: none;

            &:first-of-type {
                display: block;
            }
        }

        i.fa {
            width: 170px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            @include font-size(3);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            display: flex;
            display: none;
            align-items: center;
            justify-content: center;
            opacity: 0;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }

            &.fa-chevron-right {
                left: inherit;
                right: 0;
            }
        }
    }

    .photo-content,
    .dashboard-content {
        width: 650px;
        max-width: 100%;
        margin-right: 40px;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 1200px) {
            margin: auto;
        }
        .required {
            color: #c00;
        }
        .photo-contest-title-category {
            font-weight: 600;

            span {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 1px;

                &:after {
                    content: '|';
                    padding-left: 8px;
                    font-weight: 400;
                }
            }
        }

        .photo-title {
            font-family: $playfair;
            @include font-size(2.5);
        }

        p {
        }

        .photo-tags {
            font-weight: 600;
            text-transform: lowercase;

            a {
                margin-right: 5px;
            }
        }

        .photo-info {
            margin-top: 30px;

            table {
                width: 100%;
                border-collapse: collapse;
                padding: 0;
                margin: 0;
                td.photo-label {
                    font-weight: 600;
                }
            }

            .photo-detail-label {
                margin: 0 100px 5px 0;
                font-family: $playfair;
                @include font-size(1.9);
                font-weight: 600;
                display: inline-block;
                vertical-align: top;

                &:first-of-type {
                    margin-right: 230px;
                }
            }

            .photo-info-label {
                width: 170px;
                @include font-size(1.2);
                font-weight: 600;
                line-height: 25px;
                display: inline-block;
                vertical-align: top;
            }

            .photo-info-content {
                width: 225px;
                @include font-size(1.2);
                line-height: 25px;
                display: inline-block;
                vertical-align: top;
            }

            .photo-awards {
                @include font-size(1.2);
                line-height: 25px;
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    .sidebar {
        width: 300px;
        display: inline-block;

        @media (max-width: 1200px) {
            margin-top: 30px;
            display: block;
        }

        @media (max-width: 400px) {
            margin: 30px auto 0;
        }

        .sidebar-ad {
            width: 100%;
            height: 250px;
            background-color: #ebebeb;
        }
    }

    // Photo Category Page
    .title-filter {
        margin-bottom: 10px;
        text-align: center;
        position: relative;

        h1 {
            margin: 0 auto;
            font-weight: lighter;
            display: inline-block;
            vertical-align: top;
        }

        .photo-filter {
            margin-top: 20px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            position: absolute;
            top: -8px;
            right: 25px;

            @media (max-width: 1000px) {
                position: relative;
                top: inherit;
                right: inherit;
            }
        }
    }
}

// Login / Reset Password
.fullpage-background {
    width: 100vw;
    height: 100vh;
    min-height: 1100px;
    margin-bottom: -100px;
    background-image: url('/img/mountain-lake.jpg');
    background-position: center;
    background-size: cover;
    text-align: center;

    &:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        min-height: 1100px;
        width: 100%;
        position: absolute;
        display: block;
    }

    img#smithsonianLogo {
        width: 200px;
        margin-top: 120px;
        filter: invert(1);
    }

    h3 {
        color: #fff;
        @include font-size(2);
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        z-index: 9;
    }

    .box-container {
        width: 270px;
        margin: 15px auto;
        padding: 50px;
        background-color: #fff;
        position: relative;
        display: inline-block;
        z-index: 9;

        @media (max-width: 500px) {
            padding: 20px;
        }

        h4 {
            margin: 0 0 20px;
            font-family: $playfair;
            @include font-size(2);
        }

        p {
            margin: 20px auto 0;
            @include font-size(1);
            text-align: left;
            line-height: 1.6;
        }

        p.password-requirements {
            margin: 0 auto;
            @include font-size(0.8);
        }

        ul {
            padding: 0 15px;

            li {
                @include font-size(0.8);
                text-align: left;
            }
        }
    }

    form {
        width: 270px;
        margin: 15px auto;
        padding: 50px;
        background-color: #fff;
        position: relative;
        display: inline-block;
        z-index: 9;

        @media (max-width: 500px) {
            padding: 50px 20px;
        }

        h4 {
            margin: 0 0 20px;
            font-family: $playfair;
            @include font-size(2);
        }

        p {
            margin: 20px auto;
            @include font-size(1);
            text-align: left;
            line-height: 1.2;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        p.password-requirements {
            margin: 0 auto;
            @include font-size(0.8);
        }

        ul {
            padding: 0 15px;

            li {
                @include font-size(0.8);
                text-align: left;
            }
        }

        input {
            width: 100%;
            margin: 15px auto 5px;
            padding: 10px 15px;
            border: 2px solid #000;
            @include font-size(1);
            display: block;
            box-sizing: border-box;
        }

        input[type='checkbox'] {
            width: auto;
            display: inline;
        }

        a.reset-link {
            margin: 0;
            @include font-size(0.9);
            font-weight: 600;
            font-style: italic;
            text-align: left;
            display: block;
        }

        button {
            width: 100%;
            margin: 20px auto;
            background-color: #000;
            color: #fff;
            @include font-size(1);
            letter-spacing: 2px;
        }

        .signup-link {
            @include font-size(0.9);

            a {
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

// About page
.main.about {
    a.button {
        width: 230px;
        max-width: 100%;
        height: 60px;
        margin: 50px 0 50px 50px;
        @include font-size(1.4);
        display: inline-flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1100px) {
            margin: 0 auto 40px;
            display: flex;
        }

        @media (max-width: 400px) {
            width: auto;
        }

        &:first-of-type {
            margin-left: 0;

            @media (max-width: 1100px) {
                margin-left: auto;
            }
        }
    }
}

// FAQs
.faq {
    margin: 30px auto;
    padding: 20px 25px;
    border: 2px solid #000;
    cursor: pointer;

    .question {
        font-family: $playfair;
        @include font-size(1.5);
    }

    .answer {
        margin: 15px 0 10px 70px;
        @include font-size(1.1);
        display: none;

        &.show {
            display: block;
        }
    }

    i.fa {
        margin: -7px 50px 0 0;
        @include font-size(1.2);
        vertical-align: middle;
    }

    &.show {
        .answer {
            display: block;
        }
    }
}

:root {
    counter-reset: masonry;
}

.two-column-photo,
.three-column-photo {
    width: 480px;
    max-width: 100%;
    margin: 20px;
    display: inline-block;
    vertical-align: top;
    text-align: left;

    .photo-tags {
        margin-bottom: 20px;
        @include font-size(0.8);
        text-transform: lowercase;
        a {
            padding-right: 8px;
        }
    }

    .photo-wrapper {
        width: 100%;
        height: 400px;
        background-position: center;
        background-size: cover;

        @media (max-width: 500px) {
            height: 80vw;
        }
    }

    .photo-award {
        margin-top: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .photo-title {
        margin-bottom: 5px;
        font-family: $playfair;
        @include font-size(2);
    }

    .photographer {
        @include font-size(1.2);
    }
}

.three-column-photo {
    width: 300px;
    margin: 30px 25px;

    .photo-wrapper {
        height: 200px;
    }
}

.photo-grid {
    max-width: 1400px;
    text-align: center;
    clear: both;

    .photo-grid-photos {
        max-width: 1320px;
        margin: auto;
    }

    .photo-container {
        padding: 20px 10px 50px;
        border-bottom: 1px solid #ececec;
        columns: 4 300px;
        column-gap: 20px;
        position: relative;

        @media (max-width: 1300px) {
            columns: 3 300px;
        }

        @media (max-width: 1000px) {
            columns: 2 300px;
        }

        .grid-photo:first-of-type {
            margin-top: 0;
        }
    }

    .grid-photo {
        width: 300px;
        margin: 35px auto;
        text-align: left;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        @media (max-width: 400px) {
            width: 100%;
        }

        img {
            width: 100%;
        }
        .photographer {
            font-weight: 300;
        }

        .photo-title {
            @include font-size(1.1);
            font-weight: 600;
            line-height: 1.2;
        }

        .photo-category {
            margin: 5px 0;
            @include font-size(0.8);
            font-weight: 600;
            text-transform: uppercase;
        }

        .photo-tags {
            margin-bottom: 20px;
            @include font-size(0.7);
            text-transform: lowercase;

            a {
                padding-right: 8px;
            }
        }
    }

    .grid-ad {
        margin-bottom: 0;
        height: 250px;

        img {
            margin-bottom: 0;
            line-height: normal;
        }
    }

    #loaderGif {
        max-width: 100%;
    }

    .page-number {
        color: #ececec;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;

        span {
            padding: 5px 10px;
            background-color: #fff;
        }
    }

    .lightbox-BG {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: scroll;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 999;

        .close-wrapper {
            max-width: 1000px;
            margin: auto;
            position: relative;

            @media (max-width: 1200px) {
                max-width: 80%;
            }

            @media (max-width: 400px) {
                max-width: 100%;
            }

            .fa-times {
                width: 3rem;
                height: 3rem;
                padding: 0.5rem;
                background-color: #fff;
                border: 0.3rem solid #fff;
                border-radius: 100%;
                box-shadow: 0 0 20px #000;
                color: #000;
                font-size: 3rem;
                text-align: center;
                position: absolute;
                top: 18px;
                right: -75px;
                cursor: pointer;
                z-index: 999;

                @media (max-width: 600px) {
                    width: 2rem;
                    height: 2rem;
                    padding: 0.1rem;
                    @include font-size(2);
                    line-height: 1.1;
                    top: 3px;
                    right: -53px;
                }

                @media (max-width: 400px) {
                    top: 10px;
                    right: 0;
                }
            }
        }

        .lightbox-content {
            max-width: 1000px;
            margin: 50px auto 100px;
            padding: 50px 40px;
            background-color: #fff;
            overflow: hidden;
            position: relative;

            @media (max-width: 1200px) {
                max-width: 80%;
            }

            @media (max-width: 600px) {
                margin: 10px auto;
            }

            @media (max-width: 400px) {
                max-width: 100%;
                padding: 10px;
            }

            i.fa-times {
                @include font-size(1.3);
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }

            .lightbox-advertisement {
                width: 720px;
                height: 90px;
                margin: 0 auto 40px;
                background-color: #e3e3e3;
            }

            .photo-detail {
                img {
                    width: 100%;
                    margin: auto;
                }

                i.fa {
                    display: none;
                }
            }

            .photo-content {
                max-width: 100%;
                text-align: left;

                @media (max-width: 1200px) {
                    width: 100%;
                    margin: auto;
                }
            }
        }
    }
}

// Dashboard
.dashboard {
    max-width: 1000px;
    margin: auto;
    padding: 0 20px;

    .breadcrumbs {
        margin: 50px auto;
        text-align: left;
    }

    h1,
    h2 {
        text-align: left;
    }

    h2 {
        margin-top: 50px;
        @include font-size(1.8);
    }

    p {
        @include font-size(1.1);

        a {
            font-weight: 700;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p.blue-message,
    p.red-message {
        max-width: 580px;
        padding: 15px 20px;
        background-color: #dcf5ff;
        color: #126181;
        @include font-size(0.9);
        font-weight: 600;
        line-height: 1.5;

        i.fa {
            margin-right: 20px;
            @include font-size(1.2);
        }
    }

    p.red-message {
        background-color: #ffdcdc;
        color: #bd1b1b;
    }

    a.welcome-button {
        width: 265px;
        margin: 80px 0 0 35px;
        padding: 15px;
        display: inline-block;

        @media (max-width: 700px) {
            width: auto;
            margin: 30px auto;
            display: block;
        }

        &:first-of-type {
            margin-left: 0;

            @media (max-width: 700px) {
                margin-left: auto;
            }
        }
    }

    .dashboard-content {
        @media (max-width: 1100px) {
            width: 600px;
        }

        @media (max-width: 1000px) {
            width: auto;
            margin: auto;
        }

        @media (max-width: 600px) {
            max-width: 320px;
        }

        @media (max-width: 350px) {
            max-width: 280px;
        }
    }

    .photo-entry {
        max-width: 620px;
        padding: 35px 0;
        clear: both;

        &:after {
            content: '';
            clear: both;
            display: table;
        }

        img {
            width: 150px;
            margin: 0 30px 0 0;
            float: left;
        }

        .photo-title {
            margin-bottom: 10px;
            font-family: $opensans;
            @include font-size(1.2);
            font-weight: 600;
        }

        a {
            font-weight: 600;
            text-transform: uppercase;
        }

        p {
            margin: 0;
        }
    }

    .photo-entry ~ .photo-entry {
        border-top: 1px solid #ececec;
    }

    fieldset#file-upload-wrapper {
        width: 100%;
        padding: 0;
        border: none;
        position: relative;

        div#preview {
            position: relative;
            text-align: center;

            img#imagePreview {
                max-height: 400px;
                max-width: 400px;
            }

            div.spinner {
                width: 100%;
                height: 100%;

                div.overlay {
                    background: #0000006e;
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                img {
                    height: 100px;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }

        input#upfile {
            width: 100%;
            opacity: 0.01;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .file-upload {
            width: 100%;
            max-width: 580px;
            border: 2px dashed #000;
            margin-top: 20px;
            padding: 50px 16px;
            color: #333;
            text-align: center;
            font-size: 25px;
            font-weight: 500;
            cursor: pointer;
            display: inline-block;

            i.fa {
                margin-bottom: 15px;
                @include font-size(2.6);
                display: block;
            }

            span.browse-files {
                width: 240px;
                margin-top: 15px;
                padding: 15px 20px;
                background-color: #000;
                color: #fff;
                @include font-size(1);
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1px;
                display: inline-block;
            }
        }
    }

    select {
        width: 420px;
        max-width: 90%;
        margin: 0 0 20px;
        display: block;
        @media (max-width: 1000px) {
            width: auto;
        }
    }

    fieldset {
        margin-bottom: 30px;
        padding: 0;
        border: none;

        a {
            color: #09f;
        }

        label {
            margin-bottom: 10px;
            @include font-size(1.1);
            font-weight: 600;
            display: block;

            span.required {
                color: salmon;
                display: inline-block;
            }
        }

        span {
            display: block;
        }

        input,
        textarea {
            width: 100%;
            max-width: 600px;
            padding: 8px 10px;
            @include font-size(1);
        }

        textarea {
            height: 300px;
            margin-top: 10px;
        }

        input[type='radio'],
        input[type='checkbox'] {
            width: auto;
            margin: 20px 10px 0;
        }
    }

    input[type='submit'] {
        @include font-size(1.2);
    }

    .photo-category {
        margin: 5px 0;
        @include font-size(1.2);

        span {
            font-weight: 600;
        }
    }

    #editProfile,
    #changePassword {
        input {
            width: 100%;
            max-width: 630px;
            margin-bottom: 20px;
            padding: 12px;
            border: 1px solid #000;
            @include font-size(1);

            @media (max-width: 600px) {
                width: 80%;
            }
        }

        input.postal-code {
            width: 185px;
            margin-left: 20px;
            display: inline-block;
        }

        select {
            border: 1px solid #000;
            padding: 12px;
            display: inline-block;
        }

        label {
            margin-top: 10px;
            display: block;
        }

        .preferenceGrid {
            &.email_updates {
                input[type='checkbox'] {
                    display: inline;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 3px;
                    left: -4px;
                    margin-bottom: 0;
                }
                label {
                    display: inline-block;
                    font-size: 1.3rem;
                    font-weight: 700;
                    margin-bottom: 8px;
                    padding-left: 4px;
                    padding-bottom: 20px;
                }
                p {
                    margin: 0 0 25px 32px;
                    top: -10px;
                    position: relative;
                    font-family: $opensans;
                    font-size: 1.1rem;
                    line-height: 1.85;
                }
            }
            &.special_offers {
                input[type='checkbox'] {
                    display: inline;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 3px;
                }
                label {
                    display: none;
                }
                p {
                    position: relative;
                    top: -59px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    margin-left: 32px;
                }
            }
        }

        input[type='submit'] {
            width: 400px;
            margin-top: 30px;
            background-color: #000;
            color: #fff;
            display: block;

            @media (max-width: 600px) {
                width: auto;
            }
        }
    }

    #changePassword {
        input {
            max-width: 400px;
        }
    }

    .sidebar {
        margin-top: 125px;

        @media (max-width: 1000px) {
            display: none;
        }

        .gray-box {
            margin-bottom: 30px;
            padding: 35px 45px;
            background-color: #f6f6f6;

            h2 {
                margin: 0 0 30px;
                @include font-size(1.8);
                text-align: left;
            }

            a {
                margin: 20px 0;
                display: block;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

footer {
    margin-top: 100px;
    padding-top: 50px;
    border-top: 1px solid #ececec;
    text-align: center;

    #smithmagLogoFooter {
        width: 150px;
        margin: 0 auto 10px;
        display: block;
    }

    nav {
        a {
            padding: 25px;
            @include font-size(0.9);
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;

            @media (max-width: 600px) {
                padding: 10px;
                display: block;
            }

            &:first-of-type {
                padding-top: 0;
                @include font-size(1.6);
                font-weight: 800;
                letter-spacing: 0;
                display: block;
            }
        }
    }

    .social-icons {
        margin: 30px 0;

        i.fab {
            padding: 0 15px;
            @include font-size(1.2);
        }
    }

    .login-join {
        a {
            @include font-size(1);
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    .affiliate-links {
        padding: 40px 0;

        @media (max-width: 1000px) {
            padding: 0 0 40px;
        }

        a {
            padding: 0 20px;
            @include font-size(0.9);
            font-weight: 300;

            @media (max-width: 1200px) {
                padding: 0 9px;
            }

            @media (max-width: 1000px) {
                padding: 5px 10px;
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .subfooter {
        padding: 20px;
        background-color: #000;

        @media (max-width: 1000px) {
            padding: 30px 20px 0px;
        }

        a {
            padding: 0 15px;
            color: #ccc;
            @include font-size(0.9);
            font-weight: 300;
            border-left: 1px solid #999;

            @media (max-width: 1200px) {
                padding: 0 10px;
            }

            @media (max-width: 1000px) {
                padding: 5px 10px;
                border-left: none;
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }

            &:first-of-type {
                border-left: none;
            }
        }
    }

    .disclaimer-nav {
        padding: 60px 0 20px;
        background-color: #000;
        font-family: $opensans;

        strong {
            padding: 0 3px 0 0;
            color: #ccc;
            font-size: 12px;
            font-weight: 300;
            text-transform: none;

            @media (max-width: 1000px) {
                display: block;
            }
        }

        a {
            padding: 0 3px 0 7px;
            font-family: $opensans;
            color: #ccc;
            font-size: 12px;
            font-weight: 300;
            border-left: 1px solid #999;
            text-transform: none;

            @media (max-width: 1000px) {
                padding-bottom: 5px;
                border-left: none;
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }

            &:first-of-type {
                font-size: 12px;
                display: inline;
                font-weight: 300;
            }
        }
    }
}

ul.errorlist {
    color: #c00;
    font-weight: bold;
    background: #ffe4e4;
    list-style-type: none;
    margin: 0;
    padding: 10px 15px !important;

    li {
        margin: 0;
        padding: 0;
    }
}

.messages {
    text-align: center;
    .message-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        padding: 5px 10px;
        font-size: 14px;
        text-transform: uppercase;
    }
    .message-info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
        font-size: 14px;
        padding: 5px 10px;
        text-transform: uppercase;
    }
}
