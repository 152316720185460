@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a.anchor {
  scroll-margin-top: 150px;
}

button,
a.button,
input.button {
  padding: 12px 30px;
  border: 3px solid #000;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.2s;
  cursor: pointer;
}
button:hover,
a.button:hover,
input.button:hover {
  background-color: #000;
  color: #fff;
}

p {
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.8;
}

h1 {
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-size: 2.5rem;
  text-align: center;
}

h2 {
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: lighter;
  text-align: center;
  letter-spacing: 1px;
  line-height: 1;
}

h3 {
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1;
}

hr {
  border-top: 1px solid #ececec;
}

select {
  margin: 0 25px 0 15px;
  padding: 10px 55px 10px 20px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 0;
  font-size: 10px;
  font-size: 1rem;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%);
  background-position: calc(100% - 21px) calc(1em + 2px), calc(100% - 16px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.fullwidth-advertisement {
  padding: 10px;
  background-color: #000;
  text-align: center;
  position: relative;
  z-index: 999;
}
.fullwidth-advertisement p {
  color: #333;
  font-size: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.fullwidth-advertisement .report-ad-button {
  color: #797676 !important;
}

header {
  padding-top: 10px;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
@media (max-width: 1000px) {
  header {
    padding-bottom: 50px;
  }
}
@media (max-width: 600px) {
  header {
    padding-bottom: 0;
    min-height: 50px;
  }
}
header .header-content {
  max-width: 1400px;
  margin: auto;
  position: relative;
}
header .header-content .fa-bars {
  font-size: 15px;
  font-size: 1.5rem;
  position: absolute;
  top: 7px;
  left: 20px;
  display: none;
}
@media (max-width: 1000px) {
  header .header-content .fa-bars {
    display: block;
  }
}
header img#smithmagLogo,
header img#smithmagLogoFooter {
  width: 150px;
  margin-bottom: 5px;
}
@media (max-width: 1000px) {
  header img#smithmagLogo,
  header img#smithmagLogoFooter {
    margin-left: 55px;
    float: left;
  }
}
header .login-join,
header .entries-username {
  color: #000;
  text-transform: uppercase;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  position: absolute;
  top: 13px;
  right: 25px;
}
@media (max-width: 1000px) {
  header .login-join,
  header .entries-username {
    top: 10px;
  }
}
@media (max-width: 350px) {
  header .login-join,
  header .entries-username {
    right: 10px;
  }
}
header .login-join a span,
header .entries-username a span {
  font-weight: 300;
}
header .entries-username {
  position: absolute;
  top: -10px;
  cursor: pointer;
}
@media (max-width: 600px) {
  header .entries-username {
    position: relative;
    top: inherit;
    right: inherit;
    clear: both;
  }
}
header .entries-username .dropdown-trigger {
  padding: 20px 15px;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 600px) {
  header .entries-username .dropdown-trigger {
    margin-top: 5px;
    padding: 10px 15px;
  }
}
header .entries-username .dropdown-trigger:hover {
  background-color: #000;
  color: #fff;
}
header .entries-username .dropdown-trigger i.fa {
  color: #ccc;
  font-size: 5px;
  font-size: 0.5rem;
}
header .entries-username .dropdown-trigger i.fa-caret-down {
  display: none;
}
header .entries-username .dropdown-trigger .dropdown {
  width: 193px;
  padding: 30px 55px 40px;
  background-color: #000;
  color: #fff;
  text-align: left;
  position: absolute;
  top: 58px;
  right: 0;
  display: none;
}
@media (max-width: 600px) {
  header .entries-username .dropdown-trigger .dropdown {
    width: 100%;
    top: 44px;
    left: 0;
    right: 0;
  }
}
header .entries-username .dropdown-trigger .dropdown a {
  margin: 15px 0;
  font-size: 8px;
  font-size: 0.8rem;
  display: block;
}
header .entries-username .dropdown-trigger .dropdown strong {
  margin: 15px 0;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}
header .entries-username .dropdown-trigger:hover .dropdown {
  display: inline-block;
}
header nav {
  border-top: 1px solid #e5e5e5;
}
@media (max-width: 1000px) {
  header nav {
    height: 100vh;
    margin-top: -200vh;
    padding-top: 100px;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (max-width: 400px) {
  header nav {
    padding-top: 60px;
  }
}
header nav .fa-times {
  font-size: 18px;
  font-size: 1.8rem;
  position: absolute;
  top: 17px;
  left: 20px;
  display: none;
}
header nav a {
  margin: 0 10px;
  padding: 20px 18px 15px;
  color: #000;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  display: inline-block;
}
@media (max-width: 1100px) {
  header nav a {
    padding: 19px 10px;
  }
}
@media (max-width: 1000px) {
  header nav a {
    padding: 10px;
    display: block;
  }
}
header nav a.active, header nav a:hover {
  padding-bottom: 13px;
  border-bottom: 5px solid #000;
  text-decoration: none;
}
header nav a:first-of-type {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0;
  float: left;
}
@media (max-width: 1000px) {
  header nav a:first-of-type {
    padding: 10px;
    float: none;
  }
}
header nav a:first-of-type:hover {
  padding-bottom: 15px;
  border-bottom: none;
  text-decoration: none;
}
header nav .nav-dropdown {
  width: 200%;
  margin-left: -50%;
  padding: 50px 0 80px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  z-index: 8;
}
@media (max-width: 1000px) {
  header nav .nav-dropdown {
    border-bottom: none;
  }
}
header nav .nav-dropdown:hover {
  display: block;
}
header nav .nav-dropdown a,
header nav .nav-dropdown a:first-of-type {
  padding: 0 30px;
  font-family: "Playfair Display", serif;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
  float: none;
}
header nav .nav-dropdown a:hover,
header nav .nav-dropdown a:first-of-type:hover {
  border-bottom: none;
}
header nav .nav-dropdown .link-wrapper {
  max-width: 1140px;
  margin: auto;
  text-align: left;
}
header nav .nav-dropdown .link-wrapper a,
header nav .nav-dropdown .link-wrapper a:first-of-type {
  width: 200px;
  height: 40px;
  text-align: center;
}
header nav a.menu-option:hover + .nav-dropdown {
  display: block;
}
header nav .submit-share-search {
  margin: 15px 15px 0 0;
  float: right;
}
@media (max-width: 1000px) {
  header nav .submit-share-search {
    margin: auto;
    float: none;
  }
}
header nav .submit-share-search a {
  padding: 5px 0 19px;
  font-size: 8px;
  font-size: 0.8rem;
  font-weight: 800;
}
header nav .submit-share-search a:hover {
  border-bottom: none;
}
header nav .submit-share-search a:first-of-type {
  margin-right: 20px;
  padding: 5px 25px;
  background-color: #000;
  color: #fff;
  font-weight: 600;
  letter-spacing: 2px;
}
@media (max-width: 1000px) {
  header nav .submit-share-search a:first-of-type {
    width: 120px;
    margin: auto;
  }
}
@media (max-width: 1000px) {
  header nav .submit-share-search a:nth-of-type(2) {
    display: none;
  }
}
@media (max-width: 1000px) {
  header nav .submit-share-search a .fa-search {
    font-size: 15px;
    font-size: 1.5rem;
  }
}
header nav .submit-share-search .share-dropdown {
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
  top: 109px;
}
@media (max-width: 1000px) {
  header nav .submit-share-search .share-dropdown {
    width: auto;
    margin: auto;
    background-color: transparent;
    position: relative;
    display: block;
  }
}
header nav .submit-share-search .share-dropdown a,
header nav .submit-share-search .share-dropdown a:first-of-type {
  width: auto;
  margin: 0 20px;
  padding: 0;
  background-color: #fff;
  color: #000;
  display: inline-block;
}
header nav .submit-share-search .share-dropdown i {
  color: #000;
  font-size: 12px;
  font-size: 1.2rem;
}

.sidebar-advertisement {
  padding: 0 0 50px;
  text-align: center;
}
.sidebar-advertisement p {
  color: #333;
  font-size: 8px !important;
  font-size: 0.8rem !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.sponsorship-banner {
  position: relative;
  width: 100%;
  background: #000;
  align-items: center;
  padding: 5px 0;
}
.sponsorship-banner p {
  color: #fff;
  text-align: center;
}
@media (max-width: 600px) {
  .sponsorship-banner p {
    padding: 0 20px;
    line-height: 120%;
  }
}
.sponsorship-banner img {
  width: 67px;
  height: auto;
  padding-left: 10px;
  vertical-align: middle;
}
@media (max-width: 600px) {
  .sponsorship-banner img {
    padding-top: 10px;
    padding-left: 20px;
  }
}
@media (max-width: 600px) {
  .sponsorship-banner strong {
    display: block;
    font-size: 1.2rem;
    padding-bottom: 10px;
  }
}
.sponsorship-banner .seperator {
  padding: 0 30px;
}
@media (max-width: 600px) {
  .sponsorship-banner .seperator {
    display: none;
    padding: 0;
  }
}
@media (max-width: 600px) {
  .sponsorship-banner span {
    font-size: 0.9rem;
    width: 170px;
    padding-top: 5px;
    display: inline-block;
    text-align: right;
    position: relative;
    top: 15px;
  }
}

.billboard {
  width: 100vw;
  position: relative;
}
@media (max-width: 600px) {
  .billboard {
    height: 20vh;
    padding-top: 100px;
    width: auto;
    background: url("https://tf-cmsv2-photocontest-media.s3.amazonaws.com/photocontest/billboard/Smithmag-PhotoContest21-WebsiteBanner-1428x645-notext.jpg") top left no-repeat;
  }
}
.billboard img {
  width: 100%;
}
@media (max-width: 600px) {
  .billboard .billboard-image-photocontest {
    display: none;
  }
}
.billboard .text-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;
}
@media (max-width: 600px) {
  .billboard .text-wrapper {
    bottom: 0;
  }
}
@media (max-width: 1000px) {
  .billboard .text-wrapper {
    padding: 30px;
  }
}
.billboard .text-wrapper .headings-wrapper {
  position: absolute;
  left: 60px;
  right: 60px;
  bottom: 30px;
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper {
    left: 20px;
    right: 20px;
    bottom: 0;
    top: 20px;
  }
}
.billboard .text-wrapper .headings-wrapper h2,
.billboard .text-wrapper .headings-wrapper h3 {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: 300;
}
@media (max-width: 1000px) {
  .billboard .text-wrapper .headings-wrapper h2,
  .billboard .text-wrapper .headings-wrapper h3 {
    text-align: left;
  }
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper h2,
  .billboard .text-wrapper .headings-wrapper h3 {
    text-align: center !important;
  }
}
.billboard .text-wrapper .headings-wrapper h3 {
  margin: 20px 0;
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper h3 {
    font-size: 20px;
    font-size: 2rem;
  }
}
.billboard .text-wrapper .headings-wrapper h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 2px;
  line-height: 1.2;
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper h2 {
    padding: 0;
    margin-top: 0;
    font-size: 10px;
    font-size: 1rem;
  }
}
.billboard .text-wrapper .headings-wrapper .headingGrid {
  display: grid;
  grid-template-columns: 1fr 375px;
  gap: 40px;
  align-items: center;
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper .headingGrid {
    display: block;
    grid-template-columns: 1fr 450px;
    gap: 40px;
  }
}
.billboard .text-wrapper .headings-wrapper .headingGrid .headingRight {
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 40px;
  align-items: center;
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper .headingGrid .headingRight {
    grid-template-columns: 1fr 130px;
    gap: 30px;
  }
}
.billboard .text-wrapper .headings-wrapper .headingGrid .headingRight p {
  color: #fff;
  line-height: 120%;
  margin: 0;
  padding: 0;
  text-align: right;
}
@media (max-width: 600px) {
  .billboard .text-wrapper .headings-wrapper .headingGrid .headingRight p {
    font-size: 1rem;
  }
}
.billboard .text-wrapper .headings-wrapper .headingGrid .headingRight img {
  width: 100px;
  height: auto;
}

section.featured-entries .photo-grid-photos .photo-container {
  border-bottom: none;
}

section.photo-categories {
  padding: 0 50px;
}
@media (max-width: 600px) {
  section.photo-categories {
    padding: 0;
  }
}

.main {
  max-width: 1000px;
  margin: auto;
  padding: 0 20px;
}
.main .breadcrumbs {
  margin: 50px auto 60px;
  font-size: 10px;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.main .breadcrumbs a {
  font-weight: 700;
}
.main .breadcrumbs a:after {
  content: "/";
  padding: 0 5px 0 10px;
  font-weight: lighter;
}
.main section {
  padding: 50px 0;
}
@media (max-width: 1000px) {
  .main section {
    padding: 0;
  }
}
.main section.winners {
  padding: 0 0 50px;
}
.main section.winners-category h2,
.main section.winners-category h3 {
  margin: 20px auto;
}
.main section.winners-category h3 {
  text-transform: uppercase;
  font-weight: 600;
}
.main .text-overlay-image {
  width: 300px;
  height: 250px;
  margin: 0 auto 55px;
  margin-left: 25px;
  margin-right: 25px;
  background-position: center;
  background-size: cover;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.main .text-overlay-image .text-overlay {
  padding: 0 40px;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.main .text-overlay-image .grid-ad {
  margin-bottom: 0;
}
.main .photo-detail {
  width: 100%;
  margin: 0 auto 50px;
  text-align: center;
  position: relative;
}
.main .photo-detail img {
  width: 100%;
  display: none;
}
.main .photo-detail img:first-of-type {
  display: block;
}
.main .photo-detail i.fa {
  width: 170px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 30px;
  font-size: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
}
.main .photo-detail i.fa:hover {
  opacity: 1;
}
.main .photo-detail i.fa.fa-chevron-right {
  left: inherit;
  right: 0;
}
.main .photo-content,
.main .dashboard-content {
  width: 650px;
  max-width: 100%;
  margin-right: 40px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1200px) {
  .main .photo-content,
  .main .dashboard-content {
    margin: auto;
  }
}
.main .photo-content .required,
.main .dashboard-content .required {
  color: #c00;
}
.main .photo-content .photo-contest-title-category,
.main .dashboard-content .photo-contest-title-category {
  font-weight: 600;
}
.main .photo-content .photo-contest-title-category span,
.main .dashboard-content .photo-contest-title-category span {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.main .photo-content .photo-contest-title-category span:after,
.main .dashboard-content .photo-contest-title-category span:after {
  content: "|";
  padding-left: 8px;
  font-weight: 400;
}
.main .photo-content .photo-title,
.main .dashboard-content .photo-title {
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-size: 2.5rem;
}
.main .photo-content .photo-tags,
.main .dashboard-content .photo-tags {
  font-weight: 600;
  text-transform: lowercase;
}
.main .photo-content .photo-tags a,
.main .dashboard-content .photo-tags a {
  margin-right: 5px;
}
.main .photo-content .photo-info,
.main .dashboard-content .photo-info {
  margin-top: 30px;
}
.main .photo-content .photo-info table,
.main .dashboard-content .photo-info table {
  width: 100%;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
}
.main .photo-content .photo-info table td.photo-label,
.main .dashboard-content .photo-info table td.photo-label {
  font-weight: 600;
}
.main .photo-content .photo-info .photo-detail-label,
.main .dashboard-content .photo-info .photo-detail-label {
  margin: 0 100px 5px 0;
  font-family: "Playfair Display", serif;
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
}
.main .photo-content .photo-info .photo-detail-label:first-of-type,
.main .dashboard-content .photo-info .photo-detail-label:first-of-type {
  margin-right: 230px;
}
.main .photo-content .photo-info .photo-info-label,
.main .dashboard-content .photo-info .photo-info-label {
  width: 170px;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
}
.main .photo-content .photo-info .photo-info-content,
.main .dashboard-content .photo-info .photo-info-content {
  width: 225px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
}
.main .photo-content .photo-info .photo-awards,
.main .dashboard-content .photo-info .photo-awards {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
}
.main .sidebar {
  width: 300px;
  display: inline-block;
}
@media (max-width: 1200px) {
  .main .sidebar {
    margin-top: 30px;
    display: block;
  }
}
@media (max-width: 400px) {
  .main .sidebar {
    margin: 30px auto 0;
  }
}
.main .sidebar .sidebar-ad {
  width: 100%;
  height: 250px;
  background-color: #ebebeb;
}
.main .title-filter {
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}
.main .title-filter h1 {
  margin: 0 auto;
  font-weight: lighter;
  display: inline-block;
  vertical-align: top;
}
.main .title-filter .photo-filter {
  margin-top: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  top: -8px;
  right: 25px;
}
@media (max-width: 1000px) {
  .main .title-filter .photo-filter {
    position: relative;
    top: inherit;
    right: inherit;
  }
}

.fullpage-background {
  width: 100vw;
  height: 100vh;
  min-height: 1100px;
  margin-bottom: -100px;
  background-image: url("/img/mountain-lake.jpg");
  background-position: center;
  background-size: cover;
  text-align: center;
}
.fullpage-background:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  min-height: 1100px;
  width: 100%;
  position: absolute;
  display: block;
}
.fullpage-background img#smithsonianLogo {
  width: 200px;
  margin-top: 120px;
  filter: invert(1);
}
.fullpage-background h3 {
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  z-index: 9;
}
.fullpage-background .box-container {
  width: 270px;
  margin: 15px auto;
  padding: 50px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  z-index: 9;
}
@media (max-width: 500px) {
  .fullpage-background .box-container {
    padding: 20px;
  }
}
.fullpage-background .box-container h4 {
  margin: 0 0 20px;
  font-family: "Playfair Display", serif;
  font-size: 20px;
  font-size: 2rem;
}
.fullpage-background .box-container p {
  margin: 20px auto 0;
  font-size: 10px;
  font-size: 1rem;
  text-align: left;
  line-height: 1.6;
}
.fullpage-background .box-container p.password-requirements {
  margin: 0 auto;
  font-size: 8px;
  font-size: 0.8rem;
}
.fullpage-background .box-container ul {
  padding: 0 15px;
}
.fullpage-background .box-container ul li {
  font-size: 8px;
  font-size: 0.8rem;
  text-align: left;
}
.fullpage-background form {
  width: 270px;
  margin: 15px auto;
  padding: 50px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  z-index: 9;
}
@media (max-width: 500px) {
  .fullpage-background form {
    padding: 50px 20px;
  }
}
.fullpage-background form h4 {
  margin: 0 0 20px;
  font-family: "Playfair Display", serif;
  font-size: 20px;
  font-size: 2rem;
}
.fullpage-background form p {
  margin: 20px auto;
  font-size: 10px;
  font-size: 1rem;
  text-align: left;
  line-height: 1.2;
}
.fullpage-background form p a {
  text-decoration: underline;
}
.fullpage-background form p a:hover {
  text-decoration: none;
}
.fullpage-background form p.password-requirements {
  margin: 0 auto;
  font-size: 8px;
  font-size: 0.8rem;
}
.fullpage-background form ul {
  padding: 0 15px;
}
.fullpage-background form ul li {
  font-size: 8px;
  font-size: 0.8rem;
  text-align: left;
}
.fullpage-background form input {
  width: 100%;
  margin: 15px auto 5px;
  padding: 10px 15px;
  border: 2px solid #000;
  font-size: 10px;
  font-size: 1rem;
  display: block;
  box-sizing: border-box;
}
.fullpage-background form input[type=checkbox] {
  width: auto;
  display: inline;
}
.fullpage-background form a.reset-link {
  margin: 0;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 600;
  font-style: italic;
  text-align: left;
  display: block;
}
.fullpage-background form button {
  width: 100%;
  margin: 20px auto;
  background-color: #000;
  color: #fff;
  font-size: 10px;
  font-size: 1rem;
  letter-spacing: 2px;
}
.fullpage-background form .signup-link {
  font-size: 9px;
  font-size: 0.9rem;
}
.fullpage-background form .signup-link a {
  font-weight: 600;
}
.fullpage-background form .signup-link a:hover {
  text-decoration: underline;
}

.main.about a.button {
  width: 230px;
  max-width: 100%;
  height: 60px;
  margin: 50px 0 50px 50px;
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1100px) {
  .main.about a.button {
    margin: 0 auto 40px;
    display: flex;
  }
}
@media (max-width: 400px) {
  .main.about a.button {
    width: auto;
  }
}
.main.about a.button:first-of-type {
  margin-left: 0;
}
@media (max-width: 1100px) {
  .main.about a.button:first-of-type {
    margin-left: auto;
  }
}

.faq {
  margin: 30px auto;
  padding: 20px 25px;
  border: 2px solid #000;
  cursor: pointer;
}
.faq .question {
  font-family: "Playfair Display", serif;
  font-size: 15px;
  font-size: 1.5rem;
}
.faq .answer {
  margin: 15px 0 10px 70px;
  font-size: 11px;
  font-size: 1.1rem;
  display: none;
}
.faq .answer.show {
  display: block;
}
.faq i.fa {
  margin: -7px 50px 0 0;
  font-size: 12px;
  font-size: 1.2rem;
  vertical-align: middle;
}
.faq.show .answer {
  display: block;
}

:root {
  counter-reset: masonry;
}

.two-column-photo,
.three-column-photo {
  width: 480px;
  max-width: 100%;
  margin: 20px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.two-column-photo .photo-tags,
.three-column-photo .photo-tags {
  margin-bottom: 20px;
  font-size: 8px;
  font-size: 0.8rem;
  text-transform: lowercase;
}
.two-column-photo .photo-tags a,
.three-column-photo .photo-tags a {
  padding-right: 8px;
}
.two-column-photo .photo-wrapper,
.three-column-photo .photo-wrapper {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
}
@media (max-width: 500px) {
  .two-column-photo .photo-wrapper,
  .three-column-photo .photo-wrapper {
    height: 80vw;
  }
}
.two-column-photo .photo-award,
.three-column-photo .photo-award {
  margin-top: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.two-column-photo .photo-title,
.three-column-photo .photo-title {
  margin-bottom: 5px;
  font-family: "Playfair Display", serif;
  font-size: 20px;
  font-size: 2rem;
}
.two-column-photo .photographer,
.three-column-photo .photographer {
  font-size: 12px;
  font-size: 1.2rem;
}

.three-column-photo {
  width: 300px;
  margin: 30px 25px;
}
.three-column-photo .photo-wrapper {
  height: 200px;
}

.photo-grid {
  max-width: 1400px;
  text-align: center;
  clear: both;
}
.photo-grid .photo-grid-photos {
  max-width: 1320px;
  margin: auto;
}
.photo-grid .photo-container {
  padding: 20px 10px 50px;
  border-bottom: 1px solid #ececec;
  columns: 4 300px;
  column-gap: 20px;
  position: relative;
}
@media (max-width: 1300px) {
  .photo-grid .photo-container {
    columns: 3 300px;
  }
}
@media (max-width: 1000px) {
  .photo-grid .photo-container {
    columns: 2 300px;
  }
}
.photo-grid .photo-container .grid-photo:first-of-type {
  margin-top: 0;
}
.photo-grid .grid-photo {
  width: 300px;
  margin: 35px auto;
  text-align: left;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media (max-width: 400px) {
  .photo-grid .grid-photo {
    width: 100%;
  }
}
.photo-grid .grid-photo img {
  width: 100%;
}
.photo-grid .grid-photo .photographer {
  font-weight: 300;
}
.photo-grid .grid-photo .photo-title {
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.2;
}
.photo-grid .grid-photo .photo-category {
  margin: 5px 0;
  font-size: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.photo-grid .grid-photo .photo-tags {
  margin-bottom: 20px;
  font-size: 7px;
  font-size: 0.7rem;
  text-transform: lowercase;
}
.photo-grid .grid-photo .photo-tags a {
  padding-right: 8px;
}
.photo-grid .grid-ad {
  margin-bottom: 0;
  height: 250px;
}
.photo-grid .grid-ad img {
  margin-bottom: 0;
  line-height: normal;
}
.photo-grid #loaderGif {
  max-width: 100%;
}
.photo-grid .page-number {
  color: #ececec;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
.photo-grid .page-number span {
  padding: 5px 10px;
  background-color: #fff;
}
.photo-grid .lightbox-BG {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.photo-grid .lightbox-BG .close-wrapper {
  max-width: 1000px;
  margin: auto;
  position: relative;
}
@media (max-width: 1200px) {
  .photo-grid .lightbox-BG .close-wrapper {
    max-width: 80%;
  }
}
@media (max-width: 400px) {
  .photo-grid .lightbox-BG .close-wrapper {
    max-width: 100%;
  }
}
.photo-grid .lightbox-BG .close-wrapper .fa-times {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  background-color: #fff;
  border: 0.3rem solid #fff;
  border-radius: 100%;
  box-shadow: 0 0 20px #000;
  color: #000;
  font-size: 3rem;
  text-align: center;
  position: absolute;
  top: 18px;
  right: -75px;
  cursor: pointer;
  z-index: 999;
}
@media (max-width: 600px) {
  .photo-grid .lightbox-BG .close-wrapper .fa-times {
    width: 2rem;
    height: 2rem;
    padding: 0.1rem;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.1;
    top: 3px;
    right: -53px;
  }
}
@media (max-width: 400px) {
  .photo-grid .lightbox-BG .close-wrapper .fa-times {
    top: 10px;
    right: 0;
  }
}
.photo-grid .lightbox-BG .lightbox-content {
  max-width: 1000px;
  margin: 50px auto 100px;
  padding: 50px 40px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1200px) {
  .photo-grid .lightbox-BG .lightbox-content {
    max-width: 80%;
  }
}
@media (max-width: 600px) {
  .photo-grid .lightbox-BG .lightbox-content {
    margin: 10px auto;
  }
}
@media (max-width: 400px) {
  .photo-grid .lightbox-BG .lightbox-content {
    max-width: 100%;
    padding: 10px;
  }
}
.photo-grid .lightbox-BG .lightbox-content i.fa-times {
  font-size: 13px;
  font-size: 1.3rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.photo-grid .lightbox-BG .lightbox-content .lightbox-advertisement {
  width: 720px;
  height: 90px;
  margin: 0 auto 40px;
  background-color: #e3e3e3;
}
.photo-grid .lightbox-BG .lightbox-content .photo-detail img {
  width: 100%;
  margin: auto;
}
.photo-grid .lightbox-BG .lightbox-content .photo-detail i.fa {
  display: none;
}
.photo-grid .lightbox-BG .lightbox-content .photo-content {
  max-width: 100%;
  text-align: left;
}
@media (max-width: 1200px) {
  .photo-grid .lightbox-BG .lightbox-content .photo-content {
    width: 100%;
    margin: auto;
  }
}

.dashboard {
  max-width: 1000px;
  margin: auto;
  padding: 0 20px;
}
.dashboard .breadcrumbs {
  margin: 50px auto;
  text-align: left;
}
.dashboard h1,
.dashboard h2 {
  text-align: left;
}
.dashboard h2 {
  margin-top: 50px;
  font-size: 18px;
  font-size: 1.8rem;
}
.dashboard p {
  font-size: 11px;
  font-size: 1.1rem;
}
.dashboard p a {
  font-weight: 700;
}
.dashboard p a:hover {
  text-decoration: underline;
}
.dashboard p.blue-message,
.dashboard p.red-message {
  max-width: 580px;
  padding: 15px 20px;
  background-color: #dcf5ff;
  color: #126181;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
}
.dashboard p.blue-message i.fa,
.dashboard p.red-message i.fa {
  margin-right: 20px;
  font-size: 12px;
  font-size: 1.2rem;
}
.dashboard p.red-message {
  background-color: #ffdcdc;
  color: #bd1b1b;
}
.dashboard a.welcome-button {
  width: 265px;
  margin: 80px 0 0 35px;
  padding: 15px;
  display: inline-block;
}
@media (max-width: 700px) {
  .dashboard a.welcome-button {
    width: auto;
    margin: 30px auto;
    display: block;
  }
}
.dashboard a.welcome-button:first-of-type {
  margin-left: 0;
}
@media (max-width: 700px) {
  .dashboard a.welcome-button:first-of-type {
    margin-left: auto;
  }
}
@media (max-width: 1100px) {
  .dashboard .dashboard-content {
    width: 600px;
  }
}
@media (max-width: 1000px) {
  .dashboard .dashboard-content {
    width: auto;
    margin: auto;
  }
}
@media (max-width: 600px) {
  .dashboard .dashboard-content {
    max-width: 320px;
  }
}
@media (max-width: 350px) {
  .dashboard .dashboard-content {
    max-width: 280px;
  }
}
.dashboard .photo-entry {
  max-width: 620px;
  padding: 35px 0;
  clear: both;
}
.dashboard .photo-entry:after {
  content: "";
  clear: both;
  display: table;
}
.dashboard .photo-entry img {
  width: 150px;
  margin: 0 30px 0 0;
  float: left;
}
.dashboard .photo-entry .photo-title {
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 600;
}
.dashboard .photo-entry a {
  font-weight: 600;
  text-transform: uppercase;
}
.dashboard .photo-entry p {
  margin: 0;
}
.dashboard .photo-entry ~ .photo-entry {
  border-top: 1px solid #ececec;
}
.dashboard fieldset#file-upload-wrapper {
  width: 100%;
  padding: 0;
  border: none;
  position: relative;
}
.dashboard fieldset#file-upload-wrapper div#preview {
  position: relative;
  text-align: center;
}
.dashboard fieldset#file-upload-wrapper div#preview img#imagePreview {
  max-height: 400px;
  max-width: 400px;
}
.dashboard fieldset#file-upload-wrapper div#preview div.spinner {
  width: 100%;
  height: 100%;
}
.dashboard fieldset#file-upload-wrapper div#preview div.spinner div.overlay {
  background: rgba(0, 0, 0, 0.431372549);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.dashboard fieldset#file-upload-wrapper div#preview div.spinner img {
  height: 100px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.dashboard fieldset#file-upload-wrapper input#upfile {
  width: 100%;
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dashboard fieldset#file-upload-wrapper .file-upload {
  width: 100%;
  max-width: 580px;
  border: 2px dashed #000;
  margin-top: 20px;
  padding: 50px 16px;
  color: #333;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}
.dashboard fieldset#file-upload-wrapper .file-upload i.fa {
  margin-bottom: 15px;
  font-size: 26px;
  font-size: 2.6rem;
  display: block;
}
.dashboard fieldset#file-upload-wrapper .file-upload span.browse-files {
  width: 240px;
  margin-top: 15px;
  padding: 15px 20px;
  background-color: #000;
  color: #fff;
  font-size: 10px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
}
.dashboard select {
  width: 420px;
  max-width: 90%;
  margin: 0 0 20px;
  display: block;
}
@media (max-width: 1000px) {
  .dashboard select {
    width: auto;
  }
}
.dashboard fieldset {
  margin-bottom: 30px;
  padding: 0;
  border: none;
}
.dashboard fieldset a {
  color: #09f;
}
.dashboard fieldset label {
  margin-bottom: 10px;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 600;
  display: block;
}
.dashboard fieldset label span.required {
  color: salmon;
  display: inline-block;
}
.dashboard fieldset span {
  display: block;
}
.dashboard fieldset input,
.dashboard fieldset textarea {
  width: 100%;
  max-width: 600px;
  padding: 8px 10px;
  font-size: 10px;
  font-size: 1rem;
}
.dashboard fieldset textarea {
  height: 300px;
  margin-top: 10px;
}
.dashboard fieldset input[type=radio],
.dashboard fieldset input[type=checkbox] {
  width: auto;
  margin: 20px 10px 0;
}
.dashboard input[type=submit] {
  font-size: 12px;
  font-size: 1.2rem;
}
.dashboard .photo-category {
  margin: 5px 0;
  font-size: 12px;
  font-size: 1.2rem;
}
.dashboard .photo-category span {
  font-weight: 600;
}
.dashboard #editProfile input,
.dashboard #changePassword input {
  width: 100%;
  max-width: 630px;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #000;
  font-size: 10px;
  font-size: 1rem;
}
@media (max-width: 600px) {
  .dashboard #editProfile input,
  .dashboard #changePassword input {
    width: 80%;
  }
}
.dashboard #editProfile input.postal-code,
.dashboard #changePassword input.postal-code {
  width: 185px;
  margin-left: 20px;
  display: inline-block;
}
.dashboard #editProfile select,
.dashboard #changePassword select {
  border: 1px solid #000;
  padding: 12px;
  display: inline-block;
}
.dashboard #editProfile label,
.dashboard #changePassword label {
  margin-top: 10px;
  display: block;
}
.dashboard #editProfile .preferenceGrid.email_updates input[type=checkbox],
.dashboard #changePassword .preferenceGrid.email_updates input[type=checkbox] {
  display: inline;
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
  left: -4px;
  margin-bottom: 0;
}
.dashboard #editProfile .preferenceGrid.email_updates label,
.dashboard #changePassword .preferenceGrid.email_updates label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 8px;
  padding-left: 4px;
  padding-bottom: 20px;
}
.dashboard #editProfile .preferenceGrid.email_updates p,
.dashboard #changePassword .preferenceGrid.email_updates p {
  margin: 0 0 25px 32px;
  top: -10px;
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  line-height: 1.85;
}
.dashboard #editProfile .preferenceGrid.special_offers input[type=checkbox],
.dashboard #changePassword .preferenceGrid.special_offers input[type=checkbox] {
  display: inline;
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
}
.dashboard #editProfile .preferenceGrid.special_offers label,
.dashboard #changePassword .preferenceGrid.special_offers label {
  display: none;
}
.dashboard #editProfile .preferenceGrid.special_offers p,
.dashboard #changePassword .preferenceGrid.special_offers p {
  position: relative;
  top: -59px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-left: 32px;
}
.dashboard #editProfile input[type=submit],
.dashboard #changePassword input[type=submit] {
  width: 400px;
  margin-top: 30px;
  background-color: #000;
  color: #fff;
  display: block;
}
@media (max-width: 600px) {
  .dashboard #editProfile input[type=submit],
  .dashboard #changePassword input[type=submit] {
    width: auto;
  }
}
.dashboard #changePassword input {
  max-width: 400px;
}
.dashboard .sidebar {
  margin-top: 125px;
}
@media (max-width: 1000px) {
  .dashboard .sidebar {
    display: none;
  }
}
.dashboard .sidebar .gray-box {
  margin-bottom: 30px;
  padding: 35px 45px;
  background-color: #f6f6f6;
}
.dashboard .sidebar .gray-box h2 {
  margin: 0 0 30px;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: left;
}
.dashboard .sidebar .gray-box a {
  margin: 20px 0;
  display: block;
}
.dashboard .sidebar .gray-box a:hover {
  text-decoration: underline;
}

footer {
  margin-top: 100px;
  padding-top: 50px;
  border-top: 1px solid #ececec;
  text-align: center;
}
footer #smithmagLogoFooter {
  width: 150px;
  margin: 0 auto 10px;
  display: block;
}
footer nav a {
  padding: 25px;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 600px) {
  footer nav a {
    padding: 10px;
    display: block;
  }
}
footer nav a:first-of-type {
  padding-top: 0;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 800;
  letter-spacing: 0;
  display: block;
}
footer .social-icons {
  margin: 30px 0;
}
footer .social-icons i.fab {
  padding: 0 15px;
  font-size: 12px;
  font-size: 1.2rem;
}
footer .login-join a {
  font-size: 10px;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}
footer .affiliate-links {
  padding: 40px 0;
}
@media (max-width: 1000px) {
  footer .affiliate-links {
    padding: 0 0 40px;
  }
}
footer .affiliate-links a {
  padding: 0 20px;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 300;
}
@media (max-width: 1200px) {
  footer .affiliate-links a {
    padding: 0 9px;
  }
}
@media (max-width: 1000px) {
  footer .affiliate-links a {
    padding: 5px 10px;
    display: block;
  }
}
footer .affiliate-links a:hover {
  text-decoration: underline;
}
footer .subfooter {
  padding: 20px;
  background-color: #000;
}
@media (max-width: 1000px) {
  footer .subfooter {
    padding: 30px 20px 0px;
  }
}
footer .subfooter a {
  padding: 0 15px;
  color: #ccc;
  font-size: 9px;
  font-size: 0.9rem;
  font-weight: 300;
  border-left: 1px solid #999;
}
@media (max-width: 1200px) {
  footer .subfooter a {
    padding: 0 10px;
  }
}
@media (max-width: 1000px) {
  footer .subfooter a {
    padding: 5px 10px;
    border-left: none;
    display: block;
  }
}
footer .subfooter a:hover {
  text-decoration: underline;
}
footer .subfooter a:first-of-type {
  border-left: none;
}
footer .disclaimer-nav {
  padding: 60px 0 20px;
  background-color: #000;
  font-family: "Open Sans", sans-serif;
}
footer .disclaimer-nav strong {
  padding: 0 3px 0 0;
  color: #ccc;
  font-size: 12px;
  font-weight: 300;
  text-transform: none;
}
@media (max-width: 1000px) {
  footer .disclaimer-nav strong {
    display: block;
  }
}
footer .disclaimer-nav a {
  padding: 0 3px 0 7px;
  font-family: "Open Sans", sans-serif;
  color: #ccc;
  font-size: 12px;
  font-weight: 300;
  border-left: 1px solid #999;
  text-transform: none;
}
@media (max-width: 1000px) {
  footer .disclaimer-nav a {
    padding-bottom: 5px;
    border-left: none;
    display: block;
  }
}
footer .disclaimer-nav a:hover {
  text-decoration: underline;
}
footer .disclaimer-nav a:first-of-type {
  font-size: 12px;
  display: inline;
  font-weight: 300;
}

ul.errorlist {
  color: #c00;
  font-weight: bold;
  background: #ffe4e4;
  list-style-type: none;
  margin: 0;
  padding: 10px 15px !important;
}
ul.errorlist li {
  margin: 0;
  padding: 0;
}

.messages {
  text-align: center;
}
.messages .message-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px 10px;
  font-size: 14px;
  text-transform: uppercase;
}
.messages .message-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
}