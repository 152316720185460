@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

// fonts
$opensans: 'Open Sans', sans-serif;
$playfair: 'Playfair Display', serif;

// colors
$black: #000;
$white: #fff;
$grey: #c9c9c9;
$linkgrey: #808080;
$dark_grey: #615151;
$pink: #e92b94;
$blue: #13b4cc;
$purple: #36255a;
$red: #c00;

$artsculture: #a24b9d;
$history: #059ad1;
$innovation: #f8e208;
$science: #afc736;
$smartnews: #19b585;
$smithsonian: #1cbdc4;
$travel: #f26328;

// museum day colors
$violet: #36255a;
$gray: #3d3c3b;
$dark-gray: #161616;
$darkest-gray: #333;
$heather: #4f397d;
$orange: #f2792f;
$lemon: #fed931;

// sizes
$desktop_large: 1320px;
$desktop_default: 1076px;

body {
    margin: 0;
    padding: 0;
    // padding-top: 65px;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: manual;
    color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $playfair;
}

a {
    color: $black;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

:root {
    color-scheme: only light !important;
    --background-color: $white;
    --text-color: $black;
    --link-color: $black;
}

html[data-theme='light'] {
    --background-color: $white;
    --text-color: $black;
    --link-color: $black;
}

html[data-theme='dark'] {
    --background-color: $white !important;
    --text-color: $black !important;
    --link-color: $black !important;
}

/* site header */
.cms-toolbar-expanded header {
    top: 46px !important;
    .navigation {
        top: 46px !important;
    }
}

/* Hide stuff */
.outofsight {
    display: none;
}

@import '_header.scss';
@import '_shared.scss';
@import '_widgets.scss';
@import '_ads.scss';
@import 'article/_category.scss';
@import 'article/_article.scss';
@import 'article/_magazine.scss';
@import 'article/_longform.scss';
@import 'article/_photoessay.scss';
@import 'article/_specialreport.scss';
@import 'article/_specialcontent.scss';
@import '_homepage.scss';
@import '_museumday.scss';
@import '_flatpage.scss';
@import '_voices.scss';
@import '_video.scss';
@import '_newsletters.scss';
@import '_social.scss';
@import '_podcast.scss';
@import '_licensing.scss';
@import '_footer.scss';
@import '_print.scss';

#ot-sdk-btn-floating.ot-floating-button {
    bottom: 120px !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
    color: $black !important;
    border: 0 !important;
    padding: 0 !important;
    font-family: $opensans !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.textlabel {
    font-family: $opensans;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: $black;
    font-weight: 700;
    letter-spacing: 1px;
}

/* global circulation button styles */
.subscribe-btn,
.gift-btn,
.renew-btn {
    background: $pink;
    border: 2px solid $pink;
    height: 33px;
    margin: 0;
    padding: 0;
    &:hover {
        background: #f495c9;
        border: 2px solid #f495c9;
    }
    a {
        font-family: $opensans;
        text-transform: uppercase;
        font-size: 0.8rem;
        color: $white;
        font-weight: 700;
        letter-spacing: 2px;
        padding: 4px 10px;
        &:hover {
            text-decoration: none;
        }
    }
}

/* global alternate button styles */
.subscribe-btn-alternate,
.renew-btn-alternate,
.gift-btn-alternate {
    background: $black;
    border: 2px solid $pink;
    height: 33px;
    margin: 0;
    padding: 0;
    &:hover {
        background: $pink;
    }
    a {
        font-family: $opensans;
        text-transform: uppercase;
        font-size: 0.8rem;
        color: $white;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 4px 10px;
        &:hover {
            text-decoration: none;
        }
    }
}

/* global shop button styles */
.shop-btn {
    background: $black;
    border: 2px solid $black;
    color: $white;
    width: 100%;
    padding: 3px 0;
    a {
        font-family: $opensans;
        text-transform: uppercase;
        font-size: 0.8rem;
        color: $white;
        font-weight: 700;
        letter-spacing: 1px;
        &:hover {
            text-decoration: underline;
        }
    }
}

.main-content {
    width: $desktop_default;
    margin: 0 auto;
    padding: 0 20px;
    @media (max-width: 1100px) {
        width: auto;
    }
}

.embedly-plugin {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe,
    embed,
    object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
